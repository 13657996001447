import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Quality from '../components/images/haus'

function IndexPage() {
	return (
		<Layout>
			<SEO
				title="Start"
				keywords={[`oldenburg`, `gummelt partner`, `eigetumhswohnung`, `immobilien`]}
			/>
			<section>
				<h1>Gummelt Immobilien</h1>
				<h2>Ihr Immobilienpartner für Oldenburg, Ammerland und umzu.</h2>
				<br />
				<div className="flex  mb-4 h-auto">
					<div className="w-full text-left p-4 border-t-4 border-green-lighter">

						<ul className="list-inside list-disc">
							<li>Sie möchten zeitnah und zum
bestmöglichen Preis verkaufen?</li>
							<li>Kostenlose und qualifizierte Immobilienbewertung.</li>
							<li>Sie sind auf der Suche nach einem
neuem Objekt?</li>
							<li>Sie suchen einen Partner mit Heimatverbundenheit + ortseigenen Kontakten?</li>
	
						</ul><div className="text-center"><p><a href="/immobilien-anbieten/"><button>
						Sie suchen eine faire und kostenlose
Werteinschätzung Ihrer Immobilie?
						</button></a></p></div></div>


				</div>
				<p><Quality /></p>

			</section>
		</Layout>
	);
}

export default IndexPage;
